import QrReader from "react-qr-scanner";
import { useNavigate } from "react-router-dom";
import { useAuthDetails } from "../context/AuthDetailsContext";
import arrowIcon from "../assets/images/arrowIcon.png";

const QRReaderComponent = () => {
  const { serialNoAuth, updateQrData, qrData, id } = useAuthDetails();
  const navigate = useNavigate();

  const handleScan = (data) => {
    if (data) {
      updateQrData(data.text);
      console.log(serialNoAuth);
      console.log(qrData);
      navigate(`/allauth/${id}`);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <>
      <div className="h-screen flex flex-col justify-between min-w-[480px] ">
        <div className="flex items-center gap-1 px-3 pt-10 pb-2.5 text-xl md:text-4xl  font-medium tracking-normal bg-white border-b border-solid border-slate-500 text-zinc-900 h-40 mx-auto w-full leading-6">
          <img
            loading="lazy"
            alt="dummy"
            src={arrowIcon}
            className="shrink-0 lg:w-6 md:w-12 aspect-square rotate-180 ml-4 "
            onClick={() => navigate(`/allauth/${id}`)}
          />
          <div className="flex-1 flex items-center justify-center font-bold ">
            QR Code
          </div>
        </div>
        <div className="flex h-screen flex-col justify-center mx-auto md:w-full bg-white ">
          <div className="flex  h-full flex-col justify-center  items-center px-5 pt-20 w-full bg-zinc-900">
            <div className="mt-24 text-xl mb-20 font-bold tracking-normal leading-8 text-center text-white  md:text-3xl">
              Place the QR code within this box to scan
            </div>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              className="w-[500px] h-[500px] md:w-5/6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default QRReaderComponent;
