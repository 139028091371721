import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthDetails } from "../context/AuthDetailsContext";
import Tick from "../components/Tick";
import ConfirmationModal from "../components/ConfirmationModal";
import { useStartAuthentication } from "../interceptor/useStartAuthentication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SHP_URL, TT_BASE_URL } from "../constants";
import arrowIcon from "../assets/images/arrowIcon.png";
import logo from "../assets/images/logo.png";
import { useApi } from "../hooks/useApi";
import Spinner from "../dashboard/components/Spinner";
import { BsExclamationTriangleFill } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
const AllAuthMethods = () => {
  const [isSerial, setIsSerial] = useState(false);
  const [serialNo, setSerialNo] = useState("");
  const [isqrdata, setIsqrdata] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);

  const navigate = useNavigate();
  const data = useAuthDetails();
  const { authenticate } = useStartAuthentication();
  const {
    updateSerialNo,
    serialNoAuth,
    qrData,
    additionalImages,
    updateId,
    id,
  } = data;

  let { pathVariable } = useParams();
  console.log("pathVariable is", pathVariable);

  const { loading, postData } = useApi(TT_BASE_URL);

  useEffect(() => {
    const checkValidation = async () => {
      const endPoint = `api/v1/verification`;
      if (!id) {
        if (!pathVariable) {
          toast.error("Something went wrong", {
            onClose: () => {
              window.location.href = `${SHP_URL}`;
            },
          });
        }
        const requestBody = {
          verificationId: `${pathVariable}`,
        };
        try {
          const res = await postData(endPoint, requestBody);
          // setLoading(false);
          console.log("res is", res);
          if (res.status === 200) {
            console.log("success");
            updateId(pathVariable);
          } else {
            console.log("failed", res);
            toast.error("Verification Data not found", {
              onClose: () => {
                window.location.href = `${SHP_URL}`;
              },
            });
          }
        } catch (error) {
          toast.error("Something went wrong", {
            onClose: () => {
              window.location.href = `${SHP_URL}`;
            },
          });
        }
      }
      // setLoading(true);
    };
    checkValidation();
  }, [pathVariable]);

  useEffect(() => {
    if (qrData) {
      setIsqrdata(true);
    }
    if (serialNoAuth) {
      setIsSerial(true);
      setSerialNo(serialNoAuth);
    }
  }, [qrData, serialNoAuth]);

  const onSerialNoChangeHandler = (event) => {
    const newSerialNo = event.target.value;
    setSerialNo(newSerialNo);
    updateSerialNo(newSerialNo);
    setIsSerial(newSerialNo ? true : false);
  };

  const pictureAuthHandler = () => {
    navigate("/imageauth");
  };
  const barcodeScannerHandler = () => {
    console.log("qr started");
    navigate("/qrread");
  };
  const handleVerifyAuthenticity = async () => {
    console.log(data);
    setShowAnimation(true); // Show the animation
    const startTime = Date.now();
    const requestBody = { ...data, verificationId: `${id}` };
    let res;
    try {
      res = await authenticate(requestBody);
    } catch (error) {
      setShowAnimation(false); // Hide the animation
      toast.error("Something went wrong", {
        onClose: () => {
          window.location.href = `${SHP_URL}`;
        },
      });
      return;
    }

    const elapsedTime = Date.now() - startTime;
    const remainingTime = 5000 - elapsedTime;
    if (remainingTime > 0) {
      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    }
    setShowAnimation(false);
    navigate("/res", { state: { resultData: res } });
  };

  return (
    <div className="h-screen flex flex-col justify-between min-w-[480px] relative bg-custom-ap-bg">
      <div className="flex items-center gap-1 pt-10 pb-4 text-xl lg:h-[70px] md:h-[150px] md:text-5xl lg:text-3xl  font-medium tracking-normal bg-white border-b border-solid border-slate-500 text-zinc-900  mx-auto w-full leading-6  justify-center ">
        <img
          loading="lazy"
          alt="dummy"
          src={arrowIcon}
          className="shrink-0 lg:w-6 md:w-12 aspect-square rotate-180 ml-4 "
          onClick={() => navigate("/")}
        />
        <div className="flex-1 flex items-center justify-center font-bold lg:text-2xl md:text-6xl ml-20">
          Trust & Trace
        </div>
        <img
          src={logo}
          className="h-[100px]  lg:pb-10 md:pb-4 pr-4"
          alt="logo"
        />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="flex flex-col items-center justify-between mx-auto w-full text-base leading-6  h-full overflow-auto">
          <div>
            <div className="flex gap-2 md:mt-10 lg:mt-0 w-full text-gray-500 max-w-[700px] md:mb-60 lg:mb-6 md:min-w-[650px] sm:min-w-[600px] md:h-36 lg:h-24 ">
              <div className="grow justify-center p-4 tracking-normal w-fit items-center ">
                <p className="font-bold text-black justify-center items-center w-full text-center lg:text-2xl md:text-5xl md:my-10 lg:my-0 border-none ">
                  Item Authentication
                </p>
                <p className=" md:my-20 lg:my-6 md:text-4xl lg:text-lg ">
                  Scan and photograph distinctive element of your product to
                  verify its authenticity
                </p>
              </div>
            </div>

            <div className="flex gap-2 mt-2 w-full text-gray-500 max-w-[700px] lg:mb-4 md:mb-16 md:min-w-[650px] sm:min-w-[600px] md:h-36 lg:h-16">
              <div className=" w-14 text-5xl flex items-center justify-center ">
                {isSerial && <Tick />}
              </div>
              <form className="flex grow justify-between items-center ">
                <input
                  id="serialno"
                  type="text"
                  value={serialNo}
                  onChange={onSerialNoChangeHandler}
                  className="grow justify-center p-4 tracking-normal md:text-4xl lg:text-2xl bg-white rounded-2xl border border-solid border-slate-500 w-fit placeholder:text-gray-500 h-full hover:border-red-600 hover:border-2 focus:border-blue-900"
                  placeholder="Serial No"
                ></input>
              </form>
            </div>
            <div className="flex gap-2 mt-2 w-full text-gray-500 max-w-[700px] lg:mb-4 md:mb-16 md:min-w-[650px] sm:min-w-[600px] md:h-36 lg:h-16  ">
              <div className=" w-14 text-5xl flex items-center justify-center ">
                {isqrdata && <Tick />}
              </div>
              <button
                className="grow justify-center p-4 tracking-normal bg-white rounded-2xl border border-solid border-slate-500 w-fit hover:border-red-600 hover:border-2 "
                onClick={barcodeScannerHandler}
              >
                <div className="grow justify-center  w-fit  md:text-4xl lg:text-2xl">
                  QR Code
                </div>
              </button>
            </div>
            <div className="flex gap-2 md:mt-2 lg:mt-0 w-full text-gray-500 max-w-[700px] lg:mb-0 md:mb-16 md:min-w-[650px] sm:min-w-[600px] md:h-36 lg:h-16 ">
              <div className=" w-14 text-5xl flex items-center justify-center">
                {additionalImages.length > 0 && <Tick />}
              </div>
              <button
                onClick={pictureAuthHandler}
                className="grow justify-center p-4 tracking-normal bg-white rounded-2xl border border-solid border-slate-500 w-fit hover:border-red-600 hover:border-2 "
              >
                <div className="grow justify-center  w-fit md:text-4xl lg:text-2xl">
                  Additional Pictures
                </div>
              </button>
            </div>
            <div className="flex gap-2 md:mt-60 lg:mt-[-20px] w-full text-gray-500 max-w-[700px] md:mb-60 lg:mb-2 md:min-w-[650px] sm:min-w-[600px] md:h-36 lg:h-24  items-center md:text-4xl lg:text-2xl ">
              <BsExclamationTriangleFill className="text-black md:h-32 lg:h-10 md:w-32 lg:w-10" />
              <p className=" md:my-20 lg:my-2 lg:text-base">
                Filling in all recommended fields will increase your chance of
                authentication.
              </p>
            </div>
          </div>
          <div className="flex justify-center relative bottom-4 left-1/2 transform -translate-x-1/2 w-full  md:pb-20 lg:pb-0  ">
            <button
              className={`${
                isSerial || qrData || additionalImages.length > 0
                  ? "bg-custom-blue "
                  : "bg-gray-300 text-white font-semibold "
              } text-white rounded-full border border-solid border-slate-900 w-5/6 md:text-4xl lg:text-2xl text-center flex items-center justify-center md:h-24 lg:h-12 max-w-[700px]`}
              disabled={!(isSerial || qrData || additionalImages.length > 0)}
              onClick={handleVerifyAuthenticity}
            >
              Verify Authenticity
            </button>
          </div>
        </div>
      )}

      {showAnimation && <ConfirmationModal isOpen={showAnimation} />}

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default AllAuthMethods;
