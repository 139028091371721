import React, { useState, useEffect } from "react";
import axios from "axios";
import { TT_BASE_URL } from "../../constants";
import Spinner from "./Spinner";

export default function AggregateTable() {
  const [allAuthData, setAllAuthData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchallAuthData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${TT_BASE_URL}api/v1/dashboard/all/drift`
        );
        setAllAuthData(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchallAuthData();
  }, []);

  return (
    <div className="overflow-x-auto shadow-lg px-4 py-4 ">
      {loading ? (
        <Spinner />
      ) : (
        <table className="w-full whitespace-nowrap">
          <thead>
  <tr className="bg-custom-blue text-white h-4 md:text-2xl lg:text-lg">
    <th rowSpan={2} className="px-6 py-3 text-left font-semibold uppercase max-w-[400px] text-wrap"></th>
    <th rowSpan={2} className="border border-gray-200 px-6 py-3 text-left font-medium uppercase">
      Total
    </th>
    <th rowSpan={2} className="border border-gray-200 px-6 py-3 text-left font-medium uppercase">
      Serial Number
    </th>
    <th rowSpan={2} className="border border-gray-200 px-6 py-3 text-left font-medium uppercase">
      QR-Code
    </th>
    <th colSpan={4} className="text-center font-medium border-b-2 border-gray-200">
      <span className="my-10 font-medium">Pictures</span>
    </th>
  </tr>
  <tr className="bg-custom-blue text-white h-4 md:text-2xl lg:text-lg">
    <th className="border h-4 border-gray-200 px-6 py-1 text-left font-medium uppercase">
      Logo
    </th>
    <th className="border border-gray-200 px-6 py-1 text-left font-medium uppercase">
      Zipper/Clip
    </th>
    <th className="border border-gray-200 px-6 py-1 text-left font-medium uppercase">
      Back
    </th>
    <th className="border border-gray-200 px-6 py-1 text-left font-medium uppercase">
      Front
    </th>
  </tr>
</thead>

          <tbody>
            <tr className="bg-cyan-100 h-15 md:text-2xl lg:text-lg">
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-black font-semibold max-w-[400px] text-balance ">
                Number of positive authentications
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.totalPositive}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.serial_Number}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.qrcode}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.pictureCount.logo}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.pictureCount.zipper}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.pictureCount.back}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.pictureCount.front}
              </td>
            </tr>

            <tr className="bg-light-yellow h-15 md:text-2xl lg:text-lg">
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-black font-semibold max-w-[400px] text-balance">
                Numbers of non-positive authentications
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total ? allAuthData.total  - allAuthData.totalPositive: "" }
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total ? allAuthData?.total - allAuthData.serial_Number: ""}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total ? allAuthData?.total - allAuthData.qrcode: ""}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total ? allAuthData?.total - allAuthData.pictureCount.logo: ""}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total ? allAuthData?.total - allAuthData.pictureCount.zipper: ""}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total ? allAuthData?.total - allAuthData.pictureCount.back: ""}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total ? allAuthData?.total - allAuthData.pictureCount.front: ""}
              </td>
            </tr>
            <tr className="bg-cyan-100 h-15 md:text-2xl lg:text-lg">
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-black font-semibold max-w-[400px] text-wrap">
                Total
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total}
              </td>
              <td className="border border-gray-200 px-6 py-4 whitespace-nowrap">
                {allAuthData?.total}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}
