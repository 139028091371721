import React from "react";
import drift from "../../assets/drift-image.png";

export default function Header() {
  return (
    <div className="flex gap-5 my-10 justify-between items-center w-11/12 mx-auto md:min-h-[150px] lg:min-h-[50px] px-20 border-b-2 shadow-sm bg-white">
      <img loading="lazy" src={drift} className="aspect-square w-[90px]" />
      <div className="flex font-medium text-violet-950 justify-end text-center items-center text-4xl w-full">
        DRIFT
      </div>
    </div>
  );
}
