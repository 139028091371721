export const authDetailsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_SERIAL_NO":
      return { ...state, serialNoAuth: payload.SerialNoAuthDetails };
    case "UPDATE_ID":
      return { ...state, id: payload.ref_id };
    case "UPDATE_QR_DATA":
      return { ...state, qrData: payload.qrDatails };
    case "ADD_IMAGE":
      return { ...state, additionalImages: payload.imageList };
    default:
      throw new Error("No authDetails found");
  }
};
