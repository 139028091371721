import Lottie from "lottie-react";
import confirmationAnimation from "../assets/confirmation.json";

const ConfirmationModal = ({ isOpen }) => {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-70 z-50 ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white p-8 rounded-lg md:w-5/6 lg:max-w-md flex flex-col">
        <Lottie
          animationData={confirmationAnimation}
          loop={true} // Play animation only once
          autoplay={true} // Autoplay when modal is open and animation hasn't played
        />
        <div className="mt-4 px-4 py-2 bg-custom-blue justify-center items-center text-white rounded-md md:text-4xl lg:text-xl">
          We are authenticating your advert, please don't close the window
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
