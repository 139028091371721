import React, { useState, useEffect } from 'react';
import { VscWatch } from "react-icons/vsc";
import { HiMiniShoppingBag } from "react-icons/hi2";
import { useApi } from '../../hooks/useApi';
import { TT_BASE_URL } from '../../constants';
import Spinner from "./Spinner";

export default function CategoryResult() {
  const { loading, postData } = useApi();
  const [categoryData, setCategoryData] =useState();
  const [watchData, setWatchData] = useState({ totalSuccess: 0, categoryTotal: 0 });
  const [bagData, setBagData] = useState({ totalSuccess: 0, categoryTotal: 0 });

  useEffect(() => {
    const fetchData = async () => {
      const url = `${TT_BASE_URL}api/v1/dashboard/authInfo/brandAndCategory`;
      const data = {
        brand: "drift",
        categories: ["watch", "bag"]
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      };

      try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        setCategoryData(responseData);
        const watch = responseData.find((item) => item.category === "watch");
        const bag = responseData.find((item) => item.category === "bag");
        setWatchData(watch || { totalSuccess: 0, categoryTotal: 0 });
        setBagData(bag || { totalSuccess: 0, categoryTotal: 0 });
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex my-10 p-10 bg-white border border-solid border-zinc-400 rounded-lg gap-10 w-11/12 mx-auto">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {watchData && (
            <div className="border border-black rounded-3xl px-10 py-3 mx-auto w-full md:max-w-[800px] lg:max-w-full bg-[#0071B0] text-white">
              <VscWatch className="h-fit aspect-square mx-auto text-[96px] text-black" />
              <p className="text-2xl my-5 font-bold">Authentication requested : {watchData.categoryTotal}</p>
              <p className="text-2xl my-5 font-bold">Digitally successful : {watchData.totalSuccess} </p>
            </div>
          )}
          {bagData && (
            <div className="border border-black rounded-3xl px-10 py-3 mx-auto w-full md:max-w-[800px] lg:max-w-full bg-[#46BEAA] text-white">
              <HiMiniShoppingBag className="h-fit aspect-square mx-auto text-[96px] text-black" />
              <p className="text-2xl my-5 font-bold">Authentication requested : {bagData.categoryTotal}</p>
              <p className="text-2xl my-5 font-bold">Digitally successful : {bagData.totalSuccess} </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
