import { useState, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const useApi = (baseUrl) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getData = useCallback(
    async (endpoint) => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${baseUrl}${endpoint}`);
        setData(response.data);
        return response;
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data
          : error.message;
        setError(errorMessage);
        toast.error(errorMessage); // Show toast error message
      } finally {
        setLoading(false);
      }
    },
    [baseUrl]
  );

  const postData = useCallback(
    async (endpoint, postData) => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.post(`${baseUrl}${endpoint}`, postData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("post api response is :", response.data);
        setData(response.data);
        return response; // Return the response for status checking
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data
          : error.message;
        setError(errorMessage);
        console.log("error message is :", error);
        // toast.error(errorMessage); // Show toast error message
        return errorMessage; // Return the error response for status checking
      } finally {
        console.log("In final stage");
        setLoading(false);
      }
    },
    [baseUrl]
  );

  return { loading, error, data, getData, postData };
};
