import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { SHP_URL } from "../constants";
import arrowIcon from "../assets/images/arrowIcon.png";
import logo from "../assets/images/logo.png";
import { FaCircleCheck } from "react-icons/fa6";
import { FaCircleXmark } from "react-icons/fa6";
import { RedirectionPage } from "./RedirectionPage";
import { useState } from "react";
import { FaRegRectangleXmark } from "react-icons/fa6";
import { FaSquareCheck } from "react-icons/fa6";
import { PieChart } from "react-minimal-pie-chart";
import { useAuthDetails } from "../context/AuthDetailsContext";
const Result = () => {
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  const { id } = useAuthDetails();

  const resultData = location.state?.resultData;
  const { refId } = resultData;
  const { serialNumber, qrCode, pictures } = resultData.result;
  let avgResult = pictures
    ? pictures.reduce((sum, picture) => sum + picture.result, 0) / 4
    : 0;
  // pictures.length;

  const overAllResult = (qrCode + serialNumber + avgResult) / 3;
  // avgResult = avgResult ? avgResult + "%" : "NA";
  const handleClose = () => {
    setRedirect(true);
    setTimeout(() => {
      window.location.href = `${SHP_URL}/ad/${refId}`;
    }, 4000);
  };
  if (redirect) {
    return <RedirectionPage />;
  }
  if (!resultData) return <div>Not Found...</div>;
  return (
    <div className="h-screen flex flex-col justify-between min-w-[480px] relative">
      <div className="flex items-center gap-1 pt-10 pb-4 text-xl lg:h-[70px] md:h-[150px] md:text-5xl lg:text-3xl  font-medium tracking-normal bg-white border-b border-solid border-slate-500 text-zinc-900 mx-auto w-full leading-6  justify-center ">
        <img
          loading="lazy"
          alt="dummy"
          src={arrowIcon}
          className="shrink-0 lg:w-6 md:w-12 aspect-square rotate-180 ml-4 "
          onClick={() => navigate("/allauth/" + id)}
        />
        <div className="flex-1 flex items-center justify-center font-bold lg:text-2xl md:text-6xl ml-20">
          Trust & Trace
        </div>
        <img
          src={logo}
          className="h-[100px]  lg:pb-10 md:pb-4 pr-4"
          alt="logo"
        />
      </div>
      <div className="flex flex-col items-center justify-between mx-auto w-full text-base leading-6 bg-custom-ap-bg h-full overflow-auto">
        <div>
          <div className="flex gap-2 md:mt-10 lg:mt-0 w-full text-gray-500 max-w-[700px] md:mb-60 lg:mb-2 md:min-w-[750px] sm:min-w-[600px] md:h-36 lg:h-24 ">
            <div className="grow justify-center  p-4 tracking-normal w-fit items-center ">
              <p className="font-bold text-black justify-center items-center w-full text-center md:text-5xl lg:text-2xl md:mt-10 lg:mt-0 border-none">
                Authentication results
              </p>
              <p className="md:mt-40 lg:mt-2  font-medium md:text-4xl lg:text-xl">
                Please find your results below
              </p>
            </div>
          </div>

          <div className="flex gap-2 mt-1 w-full text-gray-500 max-w-[700px] lg:mb-4 md:mb-16 md:min-w-[750px] sm:min-w-[600px] md:h-36 lg:h-16">
            <div
              className={`flex grow justify-start items-center tracking-normal bg-white rounded-lg border-4 border-solid w-full p-6 md:text-4xl lg:text-xl text-left ${
                serialNumber === 100 ? "border-green-500" : "border-red-500"
              }`}
            >
              Serial No
            </div>
            {serialNumber === 100 ? (
              <FaSquareCheck className="md:w-24 lg:w-16 md:h-24 lg:h-16 aspect-square md:text-5xl lg:text-xl text-center ml-10 my-auto items-center justify-center text-green-600"></FaSquareCheck>
            ) : (
              <FaRegRectangleXmark className="md:w-24 lg:w-16 md:h-24 lg:h-16 aspect-square md:text-5xl lg:text-xl text-center ml-10 my-auto items-center justify-center text-red-600"></FaRegRectangleXmark>
            )}
          </div>

          <div className="flex gap-2 mt-1 w-full text-gray-500 max-w-[700px] lg:mb-4 md:mb-16 md:min-w-[750px] sm:min-w-[600px] md:h-36 lg:h-16">
            <div
              className={`flex grow justify-start items-center tracking-normal bg-white rounded-lg border-4 border-solid w-full p-6 md:text-4xl lg:text-xl text-left ${
                qrCode === 100 ? "border-green-500" : "border-red-500"
              }`}
            >
              QR Code
            </div>
            {qrCode === 100 ? (
              <FaSquareCheck className="md:w-24 lg:w-16 md:h-24 lg:h-16 aspect-square md:text-5xl lg:text-xl text-center ml-10 my-auto items-center justify-center text-green-600"></FaSquareCheck>
            ) : (
              <FaRegRectangleXmark className="md:w-24 lg:w-16 md:h-24 lg:h-16 aspect-square md:text-5xl lg:text-xl text-center ml-10 my-auto items-center justify-center text-red-600"></FaRegRectangleXmark>
            )}
          </div>

          <div className="flex gap-2 mt-2 w-full text-gray-500 max-w-[700px] lg:mb-4 md:mb-16 md:min-w-[750px] sm:min-w-[600px] md:h-36 lg:h-16">
            <div
              className={`flex grow justify-start items-center tracking-normal bg-white rounded-lg border-4 border-solid w-full min-w-[620px] p-6 md:text-4xl lg:text-xl text-left ${
                avgResult > 0 ? "border-green-500" : "border-red-500"
              }`}
            >
              Additional Images
            </div>
            <PieChart
              className="md:w-24 lg:w-16 md:h-24 lg:h-16 my-auto ml-10 items-center justify-center"
              data={[
                {
                  title: "authenticated",
                  value: avgResult,
                  color: `rgb(22, 163, 74)`,
                },
                {
                  title: "remaining",
                  value: 100 - avgResult,
                  color: "rgb(209, 213, 219)",
                },
              ]}
            />
          </div>
        </div>

        <div className="flex gap-2 w-full max-w-[700px]  md:mb-60 lg:mb-2 md:min-w-[750px] sm:min-w-[600px] md:h-36 lg:h-20 ">
          {overAllResult > 50 ? (
            <div className="flexgrow justify-center tracking-normal w-fit items-center md:text-4xl lg:text-lg  ">
              <FaCircleCheck className="h-fit aspect-square  inline-block" />
              <span className="text-gray-500 ">
                Your item has been successfully authenticated. You have earned a
                badge on your listing.
              </span>
            </div>
          ) : (
            <div className="flexgrow justify-center w-fit items-center md:text-4xl lg:text-lg  ">
              <FaCircleXmark className="h-fit aspect-square  inline-block" />{" "}
              <span className="text-gray-500">
                Your item couldn't be authentified. You cannot earn a bagde on
                your listing.
              </span>
            </div>
          )}
        </div>

        <div className="flex justify-center relative bottom-4 left-1/2 transform -translate-x-1/2 w-full  md:pb-20 lg:pb-0">
          <button
            className="bg-custom-blue text-white font-semibold rounded-full border border-solid border-slate-900 w-5/6 md:text-4xl lg:text-2xl text-center flex items-center justify-center md:h-24 lg:h-12 max-w-[700px] lg:mr-20"
            onClick={handleClose}
          >
            Back to the market place
          </button>
        </div>
      </div>
    </div>
  );
};

export default Result;
