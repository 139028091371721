import { Link } from "react-router-dom";
import PageNotFoundImage from "../assets/images/pagenotfound.png";
import logo from "../assets/images/logo.png";
export const PageNotFound = () => {
  return (
    <main>
      <div className="h-screen flex flex-col justify-between min-w-[480px] relative bg-custom-ap-bg">
        <div className="flex items-center gap-1 pt-10 pb-4 text-xl lg:h-[80px] md:h-[150px] md:text-5xl lg:text-3xl  font-medium tracking-normal bg-white border-b border-solid border-slate-500 text-zinc-900 mx-auto w-full leading-6  justify-center ">
          <div className="flex-1 flex items-center justify-center font-bold lg:text-4xl md:text-6xl">
            Trust & Trace
          </div>
          <img
            src={logo}
            className="h-[100px]  lg:pb-10 md:pb-4 pr-4"
            alt="logo"
          />
        </div>

        <div className="flex flex-col items-center my-4 ">
          <p className="text-7xl text-gray-700 font-bold my-10 dark:text-white">
            404, Oops!
          </p>
          <div className="max-w-lg">
            <img
              className="rounded"
              src={PageNotFoundImage}
              alt="404 Page Not Found"
            />
          </div>
        </div>
        <div className="text-5xl font-bold text-center mb-10 mt-10">
          <button>Back To SecondHandSale</button>
        </div>
      </div>
    </main>
  );
};
