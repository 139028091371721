import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { useAuthDetails } from "../context/AuthDetailsContext";

const Camera = () => {
  const webcamRef = React.useRef(null);
  const [images, setImages] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { addimage } = useAuthDetails();

  const videoConstraints = {
    width: 300, //3840 x 2160
    height: 500,
    facingMode: "environment",
  };
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  console.log(type);

  const capture = React.useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      addimage({ type: type, ref: imageSrc });
      setImages([...images, imageSrc]);

      navigate("/imageauth");
    }
  }, [webcamRef, images]);

  return (
    <>
      <div className="flex h-screen flex-col justify-center mx-auto md:w-full bg-white ">
        <Webcam
          audio={false}
          ref={webcamRef}
          className="w-full lg:min-w-[800px] md: h-full lg:h-[500px] flex-col justify-center  items-center "
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />

        <div className="flex flex-col justify-center items-center px-16 pt-6 pb-10 lg:mt-10 h-1/4 w-full bg-zinc-900">
          <button
            className="shrink-0 md:w-44 md:h-44 lg:w-16 lg:h-16 bg-white rounded-full"
            onClick={capture}
          ></button>
        </div>
      </div>

      {/* <div className="flex flex-col justify-center items-center h-screen">
        <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="flex flex-col justify-center items-center pb-10">
           

            <div className="flex mt-4 md:mt-6">
              <button
                href="#"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={capture}
              >
                capture more photo
              </button>
              <button
                onClick={() => navigate("/allauth")}
                className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                done
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Camera;
