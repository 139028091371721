import React from "react";
import fullBg from "../assets/images/fullBg.png";
import logo2 from "../assets/images/logo2.png";

export const RedirectionPage = () => {
  return (
    <div className="flex justify-center bg-[#C9DFE6] items-center w-full h-screen">
      <div
        className="w-full h-full"
        style={{
          backgroundImage: `url(${fullBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <img
          src={logo2}
          alt="Logo"
          className="opacity-35 lg:ml-2 lg:mt-3 xl:ml-4 xl:mt-3 md:ml-10 md:mt-10 lg:h-[45px] lg:w-[250px] xl:h-[45px] xl:w-[250px] md:h-[100px] md:w-[600px]"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center md:w-[950px] lg:w-[380px] ">
            <p className="text-black lg:text-2xl md:text-6xl mb-10">
              Thank you for using
              <span className="font-bold"> Trust &amp; Trace </span>
            </p>
            <p className="text-black lg:text-2xl md:text-6xl mb-4">
              you are redirected to<span className="font-bold"> ReN'you</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
