import { useEffect, useState,useRef } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import { TT_BASE_URL } from '../../constants';

export default function BarGraph() {
  const chartRef = useRef(null);
  const [lastFiveWeekData, setLastFiveWeekData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${TT_BASE_URL}api/v1/dashboard/drift`
        );
        const data = response.data;
        const dataArray = Object.entries(data);
        const latestFive = Object.fromEntries(
          dataArray.sort((a, b) => b[0] - a[0]).slice(0, 5)
        );
        setLastFiveWeekData(latestFive);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (lastFiveWeekData && chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        if (chartRef.current.chart) {
          chartRef.current.chart.destroy();
        }
        const dataEntries = Object.values(lastFiveWeekData);
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: dataEntries.map((entry) => "W" + entry.week),
            datasets: [
              {
                label: "Positive authentications",
                data: dataEntries.map((entry) => entry.noOfPositiveAuthentication),
                backgroundColor: "#4B7BD0",
                borderColor: "#4B7BD0",
                borderWidth: 1,
                barPercentage: 0.75,
                categoryPercentage: 0.75,
              },
              {
                label: "Sum of authentication performed",
                data: dataEntries.map((entry) => entry.numberOfAuthenticationPerformed),
                backgroundColor: "#ED7D31",
                borderColor: "#ED7D31",
                borderWidth: 1,
                barPercentage: .75,
                categoryPercentage: 0.75,
              },
            ],
          },
          options: {
            scales: {
              x: {
                stacked: true,
                title: {
                  display: true,
                  text: "Time",
                  color: "rgb(0, 0, 0)",
                  font: {
                    size: 32,
                  },
                },
                ticks: {
                  color: "rgb(0, 0, 0)",
                  font: {
                    size: 32,
                  },
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Sum of authentication",
                  color: "rgb(0, 0, 0)",
                  font: {
                    size: 32,
                  },
                },
                ticks: {
                  color: "rgb(0, 0, 0)",
                  font: {
                    size: 32,
                  },
                },
              },
            },
            plugins: {
              legend: {
                position: "top",
                font: {
                  size: 32,
                },
              },
            },
          },
        });
      }
    }
  }, [lastFiveWeekData]);
  
  return (
    <div className="mx-auto w-full">
      <div>
        <canvas ref={chartRef} width="500" height="400"></canvas>
      </div>
    </div>
  );
}
