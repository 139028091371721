import { useContext, useReducer } from "react";
import { createContext } from "react";
import { authDetailsReducer } from "../reducer/authDetailsReducer";

const initialState = {
  id: "",
  serialNoAuth: "",
  qrData: "",
  additionalImages: [],
};

const AuthDetailsContext = createContext(initialState);

export const AuthDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authDetailsReducer, initialState);

  const updateSerialNo = (serialNoAuth) => {
    dispatch({
      type: "UPDATE_SERIAL_NO",
      payload: {
        SerialNoAuthDetails: serialNoAuth,
      },
    });
  };

  const updateQrData = (qrData) => {
    dispatch({
      type: "UPDATE_QR_DATA",
      payload: {
        qrDatails: qrData,
      },
    });
  };
  const updateId = (id) => {
    dispatch({
      type: "UPDATE_ID",
      payload: {
        ref_id: id,
      },
    });
    console.log(state);
  };

  const addimage = ({ type, ref }) => {
    let updatedAdditionalImages = [];
    const existingItem = state.additionalImages.find(
      (item) => item.type === type
    );

    if (existingItem) {
      updatedAdditionalImages = state.additionalImages.map((item) =>
        item.type === type ? { type: type, ref: ref } : item
      );
    } else {
      updatedAdditionalImages = [
        ...state.additionalImages,
        { type: type, ref: ref },
      ];
    }
    console.log(updatedAdditionalImages);
    dispatch({
      type: "ADD_IMAGE",
      payload: {
        imageList: updatedAdditionalImages,
      },
    });
  };

  const value = {
    id: state.id,
    serialNoAuth: state.serialNoAuth,
    additionalImages: state.additionalImages,
    qrData: state.qrData,
    updateSerialNo,
    updateQrData,
    addimage,
    updateId,
  };
  return (
    <AuthDetailsContext.Provider value={value}>
      {children}
    </AuthDetailsContext.Provider>
  );
};

export const useAuthDetails = () => {
  const context = useContext(AuthDetailsContext);
  return context;
};
