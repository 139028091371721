import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthDetails } from "../context/AuthDetailsContext";
import arrowIcon from "../assets/images/arrowIcon.png";
import Tick from "../components/Tick";
import logo from "../assets/images/logo.png";

const AdditonalImages = () => {
  const navigator = useNavigate();
  const { additionalImages, id } = useAuthDetails();
  return (
    <div className="h-screen flex flex-col justify-between md:min-w-[480px]">
      <div className="flex items-center gap-1 px-3 py-5 lg:text-3xl md:text-6xl  font-medium tracking-normal bg-white border-b border-solid border-slate-500 justify-center text-zinc-900 lg:h-16 md:h-36 m-auto w-full leading-6 lg:mt-0 md:mt-8">
        <img
          loading="lazy"
          alt="dummy"
          src={arrowIcon}
          className="shrink-0 lg:w-6 md:w-12 aspect-square rotate-180"
          onClick={() => navigator(`/allauth/${id}`)}
        />
        <div className="flex-1 flex items-center justify-center font-bold ">
          Additional Images
        </div>
        <img
          src={logo}
          className="h-[100px]  lg:pb-10 md:pb-4 pr-4"
          alt="logo"
        />
      </div>
      <div className="flex flex-col items-center justify-items-center w-full text-base leading-6 bg-custom-ap-bg  h-full justify-between">
        <div className="w-full bg-white text-gray-200 max-w-[800px] md:min-w-[750px] sm:min-w-[630px] border border-solid border-gray-600 px-20 md:py-20 lg:py-4 rounded-3xl min-h-5/6 md:m-10 lg:m-5">
          <p className="flex lg:mt-0 md:mt-2 w-full md:text-4xl lg:text-xl   text-black justify-center max-w-[700px] lg:mb-0 md:mb-2 md:min-w-[650px] sm:min-w-[600px] sm:h-36 lg:h-16">
            Add one or more photos of your item
          </p>
          <div className="flex gap-2 md:mt-2 lg:mt-[-20px] w-full text-gray-500 max-w-[700px] md:mb-8 lg:mb-2 md:min-w-[650px] sm:min-w-[600px] sm:h-36 lg:h-16">
            <div className=" w-14 md:text-5xl lg:2xl flex items-center justify-center">
              {additionalImages.some((item) => item.type === "logo") && (
                <Tick />
              )}
            </div>
            <button
              className="grow justify-center p-4 tracking-normal bg-white rounded-lg border border-solid border-slate-500 w-fit"
              onClick={() =>
                navigator("/cam?type=" + encodeURIComponent("logo"))
              }
            >
              <div className="grow justify-center  w-fit  md:text-4xl lg:text-xl  ">
                Logo
              </div>
            </button>
          </div>

          <div className="flex gap-2 mt-2 w-full text-gray-500 max-w-[700px] md:mb-8 lg:mb-2 md:min-w-[650px] sm:min-w-[600px] sm:h-36 lg:h-16">
            <div className=" w-14 md:text-5xl lg:2xl flex items-center justify-center">
              {additionalImages.some((item) => item.type === "front") && (
                <Tick />
              )}
            </div>
            <button
              className="grow justify-center p-4 tracking-normal bg-white rounded-lg border border-solid border-slate-500 w-fit"
              onClick={() =>
                navigator("/cam?type=" + encodeURIComponent("front"))
              }
            >
              <div className="grow justify-center  w-fit  md:text-4xl lg:text-xl  ">
                Frontal
              </div>
            </button>
          </div>

          <div className="flex gap-2 mt-2 w-full text-gray-500 max-w-[700px] md:mb-8 lg:mb-2 md:min-w-[650px] sm:min-w-[600px] sm:h-36 lg:h-16">
            <div className=" w-14 md:text-5xl lg:2xl flex items-center justify-center">
              {additionalImages.some((item) => item.type === "back") && (
                <Tick />
              )}
            </div>
            <button
              className="grow justify-center p-4 tracking-normal bg-white rounded-lg border border-solid border-slate-500 w-fit"
              onClick={() =>
                navigator("/cam?type=" + encodeURIComponent("back"))
              }
            >
              <div className="grow justify-center  w-fit  md:text-4xl lg:text-xl  ">
                Back
              </div>
            </button>
          </div>

          <div className="flex gap-2 my-2 w-full text-gray-500 max-w-[700px]  md:min-w-[650px] sm:min-w-[600px] sm:h-36 lg:h-16">
            <div className=" w-14 md:text-5xl lg:2xl flex items-center justify-center">
              {additionalImages.some((item) => item.type === "zipper") && (
                <Tick />
              )}
            </div>
            <button
              className="grow justify-center p-4 tracking-normal bg-white rounded-lg border border-solid border-slate-500 w-fit"
              onClick={() =>
                navigator("/cam?type=" + encodeURIComponent("zipper"))
              }
            >
              <div className="grow justify-center  w-fit  md:text-4xl lg:text-xl  ">
                Zipper or clip
              </div>
            </button>
          </div>
        </div>
        <div className="flex justify-center relative bottom-4 left-1/2 transform -translate-x-1/2 w-full md:mt-[500px] lg:mt-[10px] md:pb-20 lg:pb-0">
          <button
            className={`${
              additionalImages.length > 0 ? " " : "hidden  "
            } bg-custom-blue font-semibold text-white rounded-full border border-solid border-slate-900 md:w-5/6 lg:w-1/2 md:text-4xl lg:text-xl   text-center flex items-center justify-center sm:h-24 lg:h-12 max-w-[700px]`}
            onClick={() => navigator(`/allauth/${id}`)}
          >
            DONE
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdditonalImages;
