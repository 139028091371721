import React, { useState } from "react";
import { TbArrowLeft } from "react-icons/tb";
import { TbArrowRight } from "react-icons/tb";
import { RxDotFilled } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import Image1 from "../assets/images/Image1.png";
import Image2 from "../assets/images/Image2.png";
import Image3 from "../assets/images/Image3.png";
import Image4 from "../assets/images/Image4.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";
import { useAuthDetails } from "../context/AuthDetailsContext";
import { SHP_URL } from "../constants";

function MyCustomCarousel() {
  const { id, updateId } = useAuthDetails();
  const slides = [
    {
      url: Image1,
    },
    {
      url: Image2,
    },
    {
      url: Image3,
    },
    {
      url: Image4,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  let param;
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (!searchParams.has("ref_id") && !id) {
      // Show error toast
      toast.error("verification Id not found!", {
        onClose: () => {
          // Redirect to another website after toast is closed
          window.location.href = SHP_URL;
        },
      });
    } else {
      param = searchParams.get("ref_id");
    }
  }, [location.search]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide
      ? navigate(`/allauth/${param}`)
      : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const skipSlide = () => {
    navigate(`/allauth/${param}`);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="relative h-screen w-screen bg-custom-lp-bg">
      <div className="absolute inset-0 flex justify-center items-center">
        <img
          className="md:w-full h-full object-cover xl:max-w-[300px] "
          src={slides[currentIndex].url}
          alt={`Slide ${currentIndex + 1}`}
        />
      </div>

      {/* Left Arrow */}
      {currentIndex > 0 && (
        <div
          className="absolute top-[90%] -translate-y-1/2 left-5  font-bold md:text-3xl lg:text-xs  rounded-full p-4 bg-custom-blue text-white cursor-pointer flex justify-center items-center "
          onClick={prevSlide}
        >
          <TbArrowLeft />
          <div>BACK</div>
        </div>
      )}
      {
        <div
          className="absolute top-[90%] -translate-y-1/2 left-[42%] font-bold md:text-3xl lg:text-xs rounded-full p-4 bg-custom-blue text-white cursor-pointer flex justify-center items-center px-12"
          onClick={skipSlide}
        >
          SKIP
        </div>
      }
      {/* Right Arrow */}
      <div
        className="absolute top-[90%] -translate-y-1/2 right-5 font-bold md:text-3xl lg:text-xs rounded-full p-4 bg-custom-blue text-white cursor-pointer flex justify-center items-center"
        onClick={nextSlide}
      >
        <div>NEXT</div> <TbArrowRight />
      </div>

      {/* Navigation dots */}
      <div className="absolute bottom-[25%] left-0 right-0 flex justify-center">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={`text-2xl cursor-pointer mx-2 ${
              slideIndex === currentIndex ? "text-blue-500" : ""
            }`}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default MyCustomCarousel;
