import React from "react";
import { Route, Routes } from "react-router-dom";
import AllAuthMethods from "../pages/AllAuthMethods";
import Camera from "../pictureauthentication/Camera";
import QRReaderComponent from "../qrauthentication/QRReaderComponent";
import AdditonalImages from "../pictureauthentication/AdditonalImages";
import MyCustomCarousel from "../components/MyCustomCarousel";
import Result from "../pages/Result";
import Dashboard from "../dashboard/Dashboard";
import { PageNotFound } from "../pages/PageNotFound";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MyCustomCarousel />} />
      <Route path="/allauth/:pathVariable" element={<AllAuthMethods />} />
      <Route path="/imageauth" element={<AdditonalImages />} />
      <Route path="/cam" element={<Camera />} />
      <Route path="/qrread" element={<QRReaderComponent />} />
      <Route path="/res" element={<Result />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRouter;
