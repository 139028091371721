import React, { useState, useEffect } from "react";
import axios from "axios";
import BarGraph from "./components/BarGraph";
import AggregateTable from "./components/AggregateTable";
import Header from "./components/Header";
import { TT_BASE_URL } from "../constants";
import Spinner from "./components/Spinner";
import { VscWatch } from "react-icons/vsc";
import { HiMiniShoppingBag } from "react-icons/hi2";
import CategoryResult from "./components/CategoryResult";
import logo from "../assets/images/logo.png";

export default function Dashboard() {
  return (
    <div className=" bg-custom-ap-bg min-h-full">
      <div className="flex items-center gap-1 pt-10 pb-4 text-xl lg:h-[70px] md:h-[150px] md:text-5xl lg:text-3xl  font-medium tracking-normal bg-white border-b border-solid border-slate-500 text-zinc-900 mx-auto w-full leading-6  justify-center ">
        <img
          src={logo}
          className="h-[100px]  lg:pb-10 md:pb-4 pr-4 ml-8"
          alt="logo"
        />
        <div className="flex-1 flex items-center justify-center font-bold lg:text-2xl md:text-6xl ml-20">
          Trust & Trace
        </div>
        <div className="flex justify-center h-10 px-2 items-center text-2xl border border-solid border-zinc-900 rounded-full w-[150px] font-semibold mr-8">
          Log out
        </div>
      </div>
      <Header />
      <div className="flex flex-col items-start  w-full md:w-full bg-custom-ap-bg min-h-screen">
        <div className="flex font-medium text-violet-950 text-4xl w-full px-10">
          Dashboard
        </div>
        <div className="flex w-screen  ml-10 mr-10 text-3xl justify-start text-start text-zinc-900 max-md:mt-10 max-md:max-w-fit">
          <div className="flex-1"></div>
          <div className="flex-1 w-10"></div>
          <div className="flex-1 bg-white p-4 ml-2 mr-2">
            <span className="font-bold text-4xl text-nowrap">Category</span>
            <ul className="list-disc">
              <li className="bg-white ml-7">Bag</li>
              <li className="bg-white ml-7">Watch</li>
            </ul>
          </div>
          <div className="flex-1 bg-white p-4 ml-2 mr-2">
            <span className=" font-bold text-4xl text-nowrap">
              Selling Platform
            </span>
            <ul className="list-disc">
              <li className="bg-white ml-7">ReN'you</li>
            </ul>
          </div>
        </div>

      
          <>
            <div className="flex flex-col mt-4 p-10 bg-white rounded-lg border border-solid border-zinc-400 w-11/12 mx-auto">
              <div className="flex gap-2 justify-center px-4 py-2 border-b border-solid border-neutral-300">
                <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
                  <div className="text-3xl font-bold tracking-normal text-zinc-900 max-md:max-w-full">
                    Number of authentications
                  </div>
                  <div className="text-2xl tracking-normal text-zinc-600 max-md:max-w-full">
                    performed the last 5 slipping weeks
                  </div>
                </div>
              </div>
              <div className="mx-auto w-full">
                <BarGraph />
              </div>
            </div>
            <div className="flex flex-col mt-10 p-10 bg-white rounded-lg border border-solid border-zinc-400 w-11/12 mx-auto">
              <div className="flex gap-2 justify-center px-4 py-6 border-b border-solid border-neutral-500">
                <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
                  <div className="md:text-3xl lg:text:text-3xl font-bold tracking-normal text-zinc-900 max-md:max-w-full ">
                    Details of authentications performed by authentication
                    methods
                  </div>
                </div>
              </div>
              <div className="mx-auto w-full md:max-w-[800px] lg:max-w-full">
                <AggregateTable />
              </div>
            </div>
            <CategoryResult/>
          </>
      
      </div>
    </div>
  );
}
