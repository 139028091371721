import axios from "axios";
import { TT_BASE_URL } from "../constants";

export const useStartAuthentication = () => {
  const authenticate = async (data) => {
    const endPoint = TT_BASE_URL + `api/v1/verification/perform`;
    const pictures = data.additionalImages.map((picture) => ({
      imageType: picture.type,
      image: picture.ref,
    }));
    const requestBody = {
      serialNumber: data.serialNoAuth,
      verificationId: data.verificationId,
      qrCode: data.qrData,
      pictures: pictures,
    };

    try {
      const response = await axios.post(endPoint, requestBody);

      return response.data;
    } catch (error) {
      console.error("Error creating ad:", error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  return { authenticate };
};
